import axios from "axios";

const jwtInterceptor = axios.create({
  baseURL: process.env.NODE_ENV === "production" ? '/' : 'https://spb03.cameracontrol.ru/',
  timeout: 30 * 60 * 1000,
  headers: { "Content-Type": "application/json" },
});

jwtInterceptor.interceptors.request.use((config) => {
  let tokensData = JSON.parse(localStorage.getItem("tokens"));
  config.headers["Authorization"] = `bearer ${tokensData.access_token}`;
  return config;
});

jwtInterceptor.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    //console.log(error);
    if (error.response.status === 401) {
      const authData = JSON.parse(localStorage.getItem("tokens"));
      const payload = {
        access_token: authData.access_token,
        refresh_token: authData.refreshToken,
      };

      let apiResponse = await axios.post(
        "https://www.cameracontrol.ru/api/refresh",
        payload
      );
      localStorage.setItem("tokens", JSON.stringify(apiResponse.data));
      error.config.headers[
        "Authorization"
      ] = `bearer ${apiResponse.data.access_token}`;
      return axios(error.config);
    } else {
      return Promise.reject(error);
    }
  }
);

export default jwtInterceptor;